import React from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Download } from "../components/download"

import { Button, Image } from "../pages"
import styled from 'styled-components'

import facebook from '../components/f.svg'
import twitter from '../components/t.svg'

const Content = styled.section`
  padding: 80px 20px;
  max-width: 1000px;
  margin: auto;
`

const ActionButton = styled.button`
  width: 46px;
  height: 46px;
  border-radius: 10000px;
  background-color: #76CDC5;
  display: flex;
  justify-content: center;
  transition: all 0.25s ease-in-out;
  appearance: none;
  border: none;
  
  &:active,
  &:focus {
    outline: none;
  }
  
  &:disabled {
    background-color: rgba(19, 19, 19, 0.75);
  }
  
  img {
    margin-bottom: 0;
    position: relative;
    left: 1px;
    // height: 20px;
    // width: auto;
  }
  
  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: rgba(58, 172, 162, 0.7);
    transition: all 0.25s ease-in-out;
  }
`

export const ShareButtons = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin-top: 10px;
  
  ${ActionButton} {
    
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  
  p {
    display: inline-block;
    font-family: fairwater-script, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #3AACA2;
    text-align: right;
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 20px;
    line-height: 1;
  }
`

const ImageActions = styled.div`
  display: flex;
  align-items: center;
`

// let fathom

const SingleImage = ({ data: { image }}) => {

  return (
    <Layout>
      <SEO
        title={'Carpool Portraits | Second Chance Church'}
        image={`https://carpoolportraits.imgix.net/${image.Key}?ar=5:4&w=3000&fit=crop&q=80&dpi=72`}
        description={'View our Mothers Day Carpool Portrait taken at Second Chance Church!'}
      />
      <Content>
        <Image src={`https://carpoolportraits.imgix.net/${image.Key}?w=1800&ar=5:4&fit=crop`} />
          <ImageActions>
            {/* share: Twitter */}
            <ShareButtons>
              <p>share on:</p>
              <ActionButton
                as={'a'}
                href={`http://twitter.com/share?text=View our Mothers Day Carpool Portrait taken at Second Chance Church!&url=https://carpoolportraits.com/${image.Key.replace(`.${image.Extension}`, '')}&hashtags=CarpoolPortraits`}
                target={'_blank'}
              >
                <img src={twitter} style={{ left: 0 }} alt="Share to Twitter"/>
              </ActionButton>
              {/* share: Facebook */}
              <ActionButton
                as={'a'}
                href={`https://www.facebook.com/sharer/sharer.php?u=https://carpoolportraits.com/${image.Key.replace(`.${image.Extension}`, '')}`} target="_blank">
                <img src={facebook} style={{ left: 0 }} alt="Share to Facebook" />
              </ActionButton>
            </ShareButtons>

            <div style={{ width: '50%' }}>
              <Button
                href={`https://carpoolportraits.imgix.net/${image.Key}?ar=5:4&w=3000&fit=crop&q=100&dpi=300&mark=https://carpoolportraits.imgix.net/border/mothers_day_frame-8x10.png&mark-align=center,middle&dl=${image.Key}`}
                download={image.Key}
                onClick={() => fathom('trackGoal', 'TBAUSPNA', 0)}
                target={'_blank'}
              >
                download <Download style={{ height: 13, width: 'auto' }} />
              </Button>
            </div>
          </ImageActions>
      </Content>
    </Layout>
  )
}

export default SingleImage

export const imageQuery = graphql`
  query GetArticle($Key: String!) {
    image: s3Image(Key: {eq: $Key}) {
      Url
      Key
      Extension
    }
  }
`